// translatedConstants.js
import { useI18n } from 'vue-i18n';

export function getTranslatedConstants() {
  const { t } = useI18n();
  const k = {};
  k["desc_req_ms"] = [
        "deepl",
        "google",
        "gpttranslate",
        "grammar",
        "summarize",
        "rephrase",
        "legal",
        "medical",
        "allergy",
        "brainstorming",
        "midjourney",
        "research_topic",
        "interview_preparation",
        "screenplay",
        "novel",
        "fitness",
        "investment_financial",
        "email",
        "travel",
        "custrw",
        "custex",
        "genexercise"
  ];
  k["text_one_line_fs_desc"] = {
        "grammar": t('Correct grammatical errors with '),
        "nchatgpt": t('Ask ChatGPT 3.5'),
        "ngpt4": t('Ask GPT-4 Turbo'),
        "ngpt4o": t('Ask GPT-4o'),
        "nhaiku": t('Ask Claude-3 (Haiku)'),
        "nsonnet": t('Ask Claude-3.5 (Sonnet)'),
        "nopus": t('Ask Claude-3 (Opus)'),
        "compare": t('Compare GPT-4 and ChatGPT'),
        "compare_opus_gpt4": t('Compare Opus and GPT-4'),
        "deepl": t('Translate text with '),
        "google": t('Translate text with '),
        "gpttranslate": t('Translate text with '),
        "summarize": t('Write a summary with '),
        "rephrase": t('Rephrase content with '),
        "legal": t('Ask legal advice with '),
        "medical": t('Ask medical or dental questions with '),
        "allergy": t('Check for potential allergens with '),
        "brainstorming": t('Generate innovative ideas with '),
        "midjourney": t('Provide tailored prompts for Midjourney with '),
        "research_topic": t('Provide in-depth research with '),
        "interview_preparation": t('Interview coaching and preparation with '),
        "screenplay": t('Write screenplays with '),
        "novel": t('Write novel with '),
        "fitness": t('Give personalized fitness guidance with '),
        "investment_financial": t('Give financial and investment insights with '),
        "email": t('Create professional emails/letters with '),
        "travel": t('Plan travel itineraries with '),
    }
  k["file_one_line_fs_desc"] = {
        "grammar": t('Correct grammatical errors in file with '),
        "conversion": t('Convert file format'),
        "deepl": t('Translate file with '),
        "google": t('Translate file with '),
        "gpttranslate": t('Translate files with'),
        "summarize": t('Create summary from file with '),
        "rephrase": t('Rephrase style and tone in files with '),
        "custrw": t('Custom - Modify file with '),
        "custex": t('Extract info from file with '),
        "genexercise": t('Create or clone exercises with '),
    }
  k["file_start_btn"] = {
        "grammar": t('Correct Grammar Now'),
        "conversion": t('Convert Format Now'),
        "deepl": t('Translate Now'),
        "google": t('Translate Now'),
        "gpttranslate": t('Translate Now'),
        "summarize": t('Summarize Now'),
        "rephrase": t('Rephrase Now'),
        "custrw": t('Modify Now'),
        "custex": t('Extract Info Now'),
        "genexercise": t('Create Exercise Now'),
    }
  k["ms_desc"] = {
        "deepl": t('DeepL Translation'),
        "google": t('Google Translation'),
        "gpt4_model": t('GPT-4 Turbo'),
        "gpt4o_model": t('GPT-4o (Omni)'),
        "claude35_sonnet": t('Claude-3.5 (Sonnet)'),
        "claude3_opus": t('Claude-3 (Opus)'),
        "claude3_haiku": t('Claude-3 (Haiku)'),
    }
  return {
    k
  };
}